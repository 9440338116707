<h4>Please make sure of the following before continuing</h4>
<ul>
    @for (requirement of requirements; track requirement) {
        <li>{{requirement}}</li>
    }
</ul>

<form [formGroup]="form">
    <nuc-form-field label="API Key">
        <nuc-input formControlName="apiKey" placeholder="Please enter an API key"></nuc-input>
    </nuc-form-field>
</form>
