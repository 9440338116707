<form [formGroup]="formGroup">
    <p class="body-strong">Items:</p>
    <nuc-dropdown [nullOption]="false" [items]="pageSizeOptions" formControlName="pageSize"></nuc-dropdown>

    <ng-container>
        <nuc-button-secondary icon="nucicon_chevron_left" (click)="onArrowClick(-1)" [disabled]="pageIndex === 1 || loading"></nuc-button-secondary>

        <ng-container>
            <span class="body-strong">Page: </span>
            <span>{{pageIndex}}</span>
        </ng-container>

        <nuc-button-secondary icon="nucicon_chevron_right" (click)="onArrowClick(1)" [disabled]="isNextPageDisabled()"></nuc-button-secondary>
    </ng-container>
</form>
