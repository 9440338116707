import {Component, DestroyRef, inject, Input, OnChanges, OnInit} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {filter} from 'rxjs/operators';
import {PaginatorService} from './paginator.service';
import {DropdownComponent, RDModule} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

interface IPageForm {
    pageSize: FormControl<number>;
}

@Component({
    selector: 'om-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    imports: [
        DropdownComponent,
        RDModule,
        ReactiveFormsModule
    ]
})
export class PaginatorComponent implements OnInit, OnChanges {
    private paginatorService = inject(PaginatorService);
    private destroyRef = inject(DestroyRef);

    @Input() public viewId: string;
    @Input() public pageSizeOptions: DropdownItem<number>[] = AppConstants.PAGE_SIZE_OPTIONS
        .map((option) => new DropdownItem(`${option}`, option));
    @Input() public loading: boolean;
    @Input() public disableNextPage = false;
    @Input() public disablePageSizeOptions: boolean;

    public pageSize: number;
    public pageIndex: number = 0;

    public formGroup: FormGroup<IPageForm>;

    public ngOnInit(): void {
        this.formGroup = new FormGroup({
            pageSize: new FormControl({value: null, disabled: this.isPageSizeDisabled()})
        });

        this.paginatorService.getStoredPageSize(this.viewId).subscribe((pageSize: number) => {
            this.pageSize = pageSize;
            this.formGroup.patchValue({pageSize: pageSize});
        });

        this.paginatorService.getPageIndex(this.viewId).subscribe((pageIndex) => this.pageIndex = pageIndex);

        this.formGroup.controls.pageSize.valueChanges
            .pipe(
                filter((v) => !!v),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((pageSize: number) => {
                if (this.pageSize !== pageSize) {
                    this.pageSize = pageSize;
                    this.paginatorService.setPageIndex(this.viewId, 1); // reset pageIndex to 1 when pageSize changes
                    this.paginatorService.setPageSize(this.viewId, pageSize);
                    this.paginatorService.storePageSize(this.viewId, pageSize);
                }
            });
    }

    public ngOnChanges(): void {
        if (this.isPageSizeDisabled()) {
            this.formGroup?.get('pageSize').disable({emitEvent: false});
        } else {
            this.formGroup?.get('pageSize').enable({emitEvent: false});
        }
    }

    private isPageSizeDisabled(): boolean {
        return this.loading || this.disablePageSizeOptions;
    }

    public isNextPageDisabled(): boolean {
        return this.loading || this.disableNextPage;
    }

    public onArrowClick(value: 1 | -1): void {
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex + value);
    }
}
