<div class="container">
    <div class="relayter-dark">
        <div class="relayter-dark-logo"></div>
    </div>

    <div class="content">
        @switch(stateChanged | async) {
            @case (ELoginState.INIT) {
                <div>
                    <div class="hero text-center">Welcome to the Overmind</div>
                    <div class="text-center grey">First things first, identify yourself</div>
                    <div class="form-container">
                        @if (formGroup) {
                            <form [formGroup]="formGroup">
                                <nuc-form-field label="E-mail">
                                    <nuc-input formControlName="email" placeholder="Enter email address" type="email" autofocus="true" required></nuc-input>
                                </nuc-form-field>
                                <div class="button-bar">
                                    <nuc-button-secondary [loading]="loading" (click)="onSubmitValid()" [disabled]="!isFormGroupValid" type="submit" text="Next"></nuc-button-secondary>
                                </div>
                            </form>
                        }
                    </div>

                </div>
            }
            @case (ELoginState.SSO) {
                <div>
                    <div class="hero text-center">Redirect to the SSO provider...</div>
                </div>
            }
        }
    </div>
</div>
