import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {JwtIntercepter} from './app/api/authentication.intercepter';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {OVERMIND_DATE_FORMATS} from './app/pipes/date.pipe';
import {AppComponent} from './app/app.component';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideRouter} from '@angular/router';
import {provideAnimations} from '@angular/platform-browser/animations';
import {ROUTES} from './app/router/router';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(ROUTES),
        provideHttpClient(
            withInterceptorsFromDi()
        ),
        provideAnimations(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtIntercepter,
            multi: true
        },
        {provide: MAT_DATE_LOCALE, useValue: navigator.language},
        {provide: MAT_DATE_FORMATS, useValue: OVERMIND_DATE_FORMATS}
    ],
}).catch(err => console.error('Error bootstrapping application:', err));
