<h1>Teams</h1>
<nuc-table
    [useButtonBar]="true"
    [tableId]="tableId"
    prefixStorageKey="overmind"
    [actions]="actions"
    [items]="teams"
    [columns]="columns"
    (actionClicked)="handleTableRowAction($event)"
    (itemClicked)="onItemClicked($event)">
    <om-paginator button-bar-right
                  [viewId]="tableId"
                  [disableNextPage]="pageIndex * pageSize >= totalItemCount">
    </om-paginator>
</nuc-table>

<ng-template #confirmDisableTeamDialog>
    <om-disable-team-form
            [team]="teamClicked"
            (formSubmittedSuccess)="disabledTeamSuccessful()"
            (formCanceled)="dialogCanceled()">
    </om-disable-team-form>
</ng-template>
