<h1>Team permissions - {{teamName}}</h1>
<nuc-table [items]="items"
           [useButtonBar]="true"
           [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
           [columns]="columns"
           [loading]="!permissionsSubscription?.closed"
           [tableId]="tableId"
           [prefixStorageKey]="'overmind'"
           (sortOptionChange)="onSortOptionChanged($event)"
           [selectionMode]="ESelectionMode.MULTI"
           [selection]="selection">
    <ng-container button-bar-left>
        <nuc-input class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="setPageIndex()"
                   placeholder="Search permissions">
        </nuc-input>
    </ng-container>
    <ng-container button-bar-right>
        @if (!canDeactivate()) {
            <nuc-button-primary
                [disabled]="!permissionsSubscription?.closed"
                (click)="openConfirmDialog()"
                text="Save changes">
            </nuc-button-primary>
        }
        <om-paginator [viewId]="tableId"
                      [disableNextPage]="pageSize * pageIndex >= total"
                      [loading]="!permissionsSubscription?.closed">
        </om-paginator>
    </ng-container>
</nuc-table>
