import {Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TeamModel, TeamPatchModel} from '../../../models/response/team.model';
import {ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {ETeamJobTypes, TeamService} from '../../../api/services/team.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentConfig,
    IActionClickEvent,
    IItemClickEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogCustomContentRef,
    NucDialogCustomContentService,
    NucDialogRef,
    NucDialogService,
    NucDialogTextConfirmConfig,
    NucDialogTextConfirmData,
    NucDialogTextConfirmService, RDModule
} from '@relayter/rubber-duck';
import {Toaster} from '../../../classes/toaster.class';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FileSizePipe} from 'ngx-filesize';
import {PaginatorComponent} from '../../../components/paginator/paginator.component';
import {DisableTeamFormComponent} from '../../../forms/disable-team/disable-team-form.component';

@Component({
    selector: 'om-teams',
    styleUrls: ['./teams.component.scss'],
    templateUrl: './teams.component.html',
    providers: [PaginatorService],
    imports: [
        PaginatorComponent,
        RDModule,
        DisableTeamFormComponent
    ]
})
export class TeamsComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public tableId = 'teams-component';
    public teams: TeamModel[] = [];
    public pageIndex: number;
    public pageSize: number;
    public totalItemCount: number;
    public teamClicked: TeamModel;
    private disableAction = {title: 'Disable team', icon: 'nucicon_remove_line'} as ITableAction;
    private enableAction = {title: 'Enable team', icon: 'nucicon_check_round_fill'} as ITableAction;
    private deleteAction = {title: 'Delete team', icon: 'nucicon_trash_line'} as ITableAction;
    private teamPermissionsAction = {title: 'Team permissions', icon: 'nucicon_gear'} as ITableAction;
    private confirmDisabledDialogRef: NucDialogCustomContentRef<any>;
    public enableDialog: NucDialogRef;

    public actions = [
        this.disableAction,
        this.enableAction,
        this.deleteAction,
        this.teamPermissionsAction
    ];

    public columns: ITableColumn[] = [
        {
            title: 'Team name',
            key: 'name'
        },
        {
            title: 'Owner',
            key: 'owner.fullName'
        },
        {
            title: 'User limit',
            key: 'userLimit'
        },
        {
            title: 'Status',
            key: 'status'
        },
        {
            title: 'Storage limit',
            key: 'storageLimit',
            format: (v) => {
                return new FileSizePipe().transform(v).toString();
            }
        },
        {
            title: 'Publication item limit',
            key: 'publicationItemLimit'
        },
        {
            title: 'InDesign generation limit',
            key: 'indesignGenerationLimit'
        }
    ];

    @ViewChild('confirmDisableTeamDialog', {static: true}) public confirmDisableTeamDialog: TemplateRef<any>;

    constructor(private teamService: TeamService,
                private router: Router,
                private route: ActivatedRoute,
                private dialogService: NucDialogService,
                private dialogTextConfirmService: NucDialogTextConfirmService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private paginatorService: PaginatorService) {
    }

    public ngOnInit() {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getTeams();
            });

        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public getTeams(): void {
        this.teamService.getTeams(this.pageSize, (this.pageIndex - 1) * this.pageSize)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: ARPagedResponseDataModel<TeamModel>) => {
                    this.teams = res.items;
                    this.teams.forEach((team) => {
                        team.actions = team.disabled
                            ? [this.enableAction, this.deleteAction]
                            : [this.disableAction, this.teamPermissionsAction];
                    });
                    this.totalItemCount = res.total;
                },
                error: Toaster.handleApiError
            });
    }

    public onItemClicked(event: IItemClickEvent): void {
        this.router.navigate([event.item._id], {relativeTo: this.route});
    }

    /**
     * On table action clicked, check the action type first then see if the template is in use
     * if it's in use, then throw a warning otherwise open delete modal
     */
    public handleTableRowAction(event: IActionClickEvent): void {
        this.teamClicked = event.item as TeamModel;

        switch (event.action) {
            case this.disableAction: {
                this.confirmDisabledDialogRef = this.dialogCustomContentService.open(this.confirmDisableTeamDialog,
                    new DialogCustomContentConfig('Disable a team'));
                break;
            }
            case this.enableAction: {
                this.openEnableDialog(this.teamClicked);
                break;
            }
            case this.deleteAction: {
                this.openDeleteTeamDialog(this.teamClicked);
                break;
            }
            case this.teamPermissionsAction: {
                this.router.navigate([this.teamClicked._id, 'permissions'], {relativeTo: this.route});
                break;
            }
            default: {
                Toaster.notYetImplementedError();
                break;
            }
        }
    }

    public openDeleteTeamDialog(team: TeamModel): void {
        const dialogData = new NucDialogTextConfirmData(
            'Team name',
            'Enter team name',
            team.name,
            new DialogButtonConfig(BUTTON_TYPE.DESTRUCTIVE, 'Delete')
        );
        const dialogConfig = new NucDialogTextConfirmConfig('Delete team',
            dialogData, `You are about to delete ${team.name} from Relayter,
            this means all data will be gone forever for this team. Are you sure?`);

        const dialog = this.dialogTextConfirmService.open(dialogConfig);
        dialog.afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((deletionConfirmed: boolean) => {
                if (deletionConfirmed) this.postDeleteTeamJob(team);
            });
    }

    private postDeleteTeamJob(team: TeamModel): void {
        this.teamService.postJob(ETeamJobTypes.DELETE_TEAM_JOB, {teamId: team._id})
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => Toaster.success('Delete team job is scheduled'),
                error: Toaster.error
            });
    }

    public openEnableDialog(team: TeamModel): void {
        const dialogConfig = new NucDialogConfigModel('Enable a team', `You are about to enable ${team.name} in Relayter. Are you sure?`);

        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => this.enableDialog.close());
        dialogConfig.addAction('Enable', BUTTON_TYPE.PRIMARY).subscribe(() => this.updateTeamStatus(team, false));

        this.enableDialog = this.dialogService.openDialog(dialogConfig);
    }

    private updateTeamStatus(team: TeamModel, disabled: boolean): void {
        this.teamService.patchTeam(team._id, new TeamPatchModel(disabled))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    ARLogger.info(`Team successfully ${disabled ? 'disabled' : 'enabled'}`);
                    this.enableDialog.close();
                    this.getTeams();
                },
                error: (error) => {
                    this.enableDialog.close();
                    Toaster.error(`${disabled ? 'Disabling' : 'Enabling'} team went wrong, Error: ` + error.message);
                }
            });
    }

    private closeDialog(): void {
        if (this.confirmDisabledDialogRef) {
            this.confirmDisabledDialogRef.close();
        }
    }

    public disabledTeamSuccessful(): void {
        this.getTeams();
        this.closeDialog();
    }

    public dialogCanceled(): void {
        this.closeDialog();
    }
}
