<div class="admin-container">
    <div class="menu">
            <div class="logo"></div>
            <div class="menu-items">
                <div class="menu-item" routerLink="/teams">
                    <i class="demo-icon nucicon_dribbble"></i>
                </div>
            </div>
            <nuc-button-destructive class="logout" text="logout" (click)="btnLogoutClicked()"></nuc-button-destructive>
    </div>
    <div class="content-container">
        <router-outlet></router-outlet>
    </div>
</div>
