import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    InputComponent,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService,
    RDModule
} from '@relayter/rubber-duck';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface IApiKeyDialogData {
    requirements: string[];
}

interface IApiKeyForm {
    apiKey: FormControl<string>;
}

@Component({
    selector: 'om-api-key-dialog',
    templateUrl: './api-key-dialog.component.html',
    styleUrls: ['./api-key-dialog.component.scss'],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        RDModule,
        InputComponent
    ]
})
export class ApiKeyDialogComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public requirements: string[];

    public form: FormGroup<IApiKeyForm>;

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) private modalData: IApiKeyDialogData ) {
        this.requirements = modalData.requirements;
    }

    public ngOnInit(): void {
        this.form = new FormGroup<IApiKeyForm>({
            apiKey: new FormControl('', Validators.required)
        });

        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new DialogCustomContentActionModel(cancelButton);

        const successButton = new DialogButtonConfig(BUTTON_TYPE.PRIMARY, 'Populate', false, false, true);
        const success = new DialogCustomContentActionModel(successButton);

        cancel.observable.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => this.dialogCustomContentService.close());

        success.observable.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => this.dialogCustomContentService.close(this.form.value.apiKey));

        this.dialogCustomContentService.setDialogActions([cancel, success]);

        this.form.statusChanges.pipe(
            map((status) => status === 'VALID')
        ).subscribe((valid) => successButton.disabled = !valid);
    }
}
