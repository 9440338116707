import {Component, inject, OnInit} from '@angular/core';
import {Auth0Service} from './services/auth0.service';
import {RouterOutlet} from '@angular/router';
import {RDModule, ToastService} from '@relayter/rubber-duck';
import {Toaster} from './classes/toaster.class';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'om-root',
    templateUrl: './app.component.html',
    imports: [
        CommonModule,
        RouterOutlet,
        RDModule
    ]
})
export class AppComponent implements OnInit {
    private toastService = inject(ToastService);
    private auth0 = inject(Auth0Service);

    public ngOnInit() {
        Toaster.initialize(this.toastService);
        // In order to restore local authentication status after a refresh, we'll call the localAuthSetup() method when the app initializes.
        this.auth0.localAuthSetup();
    }
}
