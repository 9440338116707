import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RDModule} from '@relayter/rubber-duck';
import {GetDragDropTitlePipe} from '../../pipes/get-drag-drop-title.pipe';

@Component({
    selector: 'om-file-input',
    styleUrls: ['file-input.component.scss'],
    templateUrl: 'file-input.component.html',
    imports: [
        RDModule,
        GetDragDropTitlePipe
    ]
})
export class FileInputComponent {
    @Input() public inActiveTitle: string;
    @Output() public fileChanged = new EventEmitter<File>();

    public isDragging = false;

    /**
     * On files changed, disable isDragging mode and emit any valid changed files
     */
    public onFilesChanged(event): void {
        this.isDragging = false;
        let file: File;
        if (event.target.files && event.target.files.length > 0) {
            file = event.target.files[0];
        }
        this.fileChanged.emit(file);
    }
}
